import React from 'react'

const AboutWe = () => {
    return (
        <>
            <div className="AboutWe">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="we_name">O праваx <span>“ASVIZ”</span> </div>
                        </div>

                        <div className="col-lg-10 col-12 d-flex flex-column justify-content-center ">
                            <div className="we_h">ОС ВЭТИ «ASVIZ»  имеет административную, финансовую и юридическую независимость от изготовителей (поставщиков) ВЭТИ и их заказчиков (потребителей) и обладает необходимой технической компетенцией и соответствующим  правовым статусом.         ОС для покрытия своих обязательств, возникающих в процессе деятельности обладает необходимыми материальными и финансовыми резервами, которые включают Страховой полис, а также материальные ценности (оргтехника, оборудование и др.).</div>

                            <div className="we_h">При проведении процедур сертификации ОС ВЭТИ использует соответствующие права ООО “ASVIZ” (юридическая и административная ответственность, расчетный счет в банке и т.п.). Основные средства, которыми располагает ОС ВЭТИ,  являются его собственностью.</div>

                        </div>
                    </div>
                    <div className="row justify-content-evenly">
                        <div className="col-md-4 col-sm-5 col-6"><img className='w-100' src="/img/sertifikat_2.jpg" alt="" /></div>
                        <div className="col-md-4 col-sm-5 col-6"><img className='w-100' src="/img/sertifikat.png" alt="" /></div>
                        <div className="col-md-4 col-sm-5 col-6 mt-4 mt-md-0"><img className='w-100' src="/img/sertifikat_3.jpg" alt="" /></div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="we_h_2">К основным функциям относятся:</div>
                            <div className="we_h_2">-     разработка и реализация задач общей политики в области сертификации   ВЭТИ в соответствии с законами Республики Узбекистан “О сертификации продукции и услуг”, “О защите прав потребителей”, Постановления Кабинета министров Республики Узбекистан “О дополнительных мерах по упрощению процедуры сертификации изделий” и других законодательных и нормативных актов Республики Узбекистан, а также в соответствии с требованиями  НД ГСС Уз, ГСИ Уз и НСС Уз;</div>
                            <div className="we_h_2">-     содействие гармонизации норм и правил, действующих в системе ГАК        “Узбекэнерго”, требованиям международных стандартов;</div>
                            <div className="we_h_2">-     содействие  в повышении конкурентоспособности ВЭТИ, производимых в Республике Узбекистан, на отечественном и зарубежном рынках;</div>
                            <div className="we_h_2">-     проведение работ по сертификации ВЭТИ (в том числе импортируемых из-за рубежа);</div>
                            <div className="we_h_2">-     осуществление деятельности по постепенному переходу к международным стандартам;</div>
                            <div className="we_h_2">-     инспекционный контроль за стабильностью характеристик сертифицированных ВЭТИ;</div>
                            <div className="we_h_2">-     рассмотрение апелляций на деятельность персонала и ОС ВЭТИ в целом.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutWe