import React from 'react'
import Complaints from './Complaints'
import Footer from './Footer'

const Sertif5 = () => {
    return (
        <>
            <div className="Sertif5">
                <Complaints />
            </div>
            <Footer />
        </>
    )
}

export default Sertif5