import React from 'react'
import Footer from './Footer'
import Responsibly from './Responsibly'

const Sertif4 = () => {
    return (
        <>
            <div className="Sertif4">
                <Responsibly />
            </div>
            <Footer/>
        </>
    )
}

export default Sertif4