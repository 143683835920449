import React from 'react'

const Complaints = () => {
    return (
        <>
            <div className="Complaints">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="com_name">
                                Жалобы и апелляции
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="com_p">
                                O’z DSt ISO/PAS 17003:2016 «Оценка соответствия. Жалобы и апелляции. Принципы и требования».
                            </div>
                            <div className="com_p">
                                Жалобы и апелляции подаются в случаях:
                            </div>
                            <div className="com_p">
                                <span> -неудовлетворенности деятельностью ОС;</span>
                            </div>
                            <div className="com_p">
                                <span> -отказа в выдаче сертификата на продукцию;</span>
                            </div>
                            <div className="com_p">
                                <span>-аннулирования или приостановки действия сертификата.</span>
                            </div>
                            <div className="com_p">
                                1.	Жалобы и апелляции могут подаваться в ОС, НСС РУз, НСА РУз, в суд. При этом описание процесса обращения с жалобами и апелляциями должен быть доступен по запросу любой заинтересованной стороне.
                            </div>
                            <div className="com_p">
                                2.	Рассмотрение апелляции на решение ОС по сертификации входит в компетенцию Узбекского агентства по Техническому Регулированию или суда, о чём уведомляется заявитель.
                            </div>
                            <div className="com_p">
                                3.	ОС приступает к рассмотрению жалобы или апелляции только после подтверждения и убеждения, что именно она относится к его деятельности по сертификации.
                            </div>
                            <div className="com_p">
                                4.	В случае возникновения спора заинтересованные стороны предпринимают все возможные действия для разрешения спорного вопроса между собой.
                            </div>
                            <div className="com_p">
                                5.	ОС несет ответственность за все решения на всех уровнях по процессу обращения с жалобами.
                            </div>
                            <div className="com_p">
                                6.	Расследование и принятие решения по жалобам не должно в результате приводить к каким-либо дискриминационным действиям.
                            </div>

                            <div className="com_name_2">
                                Установление обратной связи с заявителям
                            </div>
                            <div className="com_p">
                                1.	В случае не удовлетворения со стороны заявителя на оказываемые услуги ОС и сотрудников ОС, заявитель вправе потребовать книгу жалоб и предложений, а также внести свои предложения по улучшению оказываемых услуг.
                            </div>
                            <div className="com_p">
                                2.	После получения жалобы или апелляции ОС подтверждает, относится ли жалоба или апелляция к его деятельности по сертификации и только в этом случае принимает к рассмотрению. При этом заявитель уведомляется, что рассмотрение апелляции на решения ОС по сертификации входит в компетенцию Узбекского агентства по Техническому Регулированию или суда.
                            </div>
                            <div className="com_p">
                                3.	Руководитель ОС постоянно изучает жалобы, предложения и своевременно выполняет корректирующие действия.
                            </div>


                            <div className="com_name_2">
                                Управление жалобами.
                            </div>
                            <div className="com_p">
                                4.	Жалобы заявителем могут подаваться в случаях:
                            </div>
                            <div className="com_p">
                                <span>-результаты работ по сертификации не удовлетворительные;</span>
                            </div>
                            <div className="com_p">
                                <span> -при нетактичном поведении специалистов ОС;</span>
                            </div>
                            <div className="com_p">
                                5.	Жалоба подается устно или письменно руководителю ОС.
                            </div>
                            <div className="com_p">
                                6.	Заявитель, который подает жалобу, должен быть ознакомлен с настоящим процедурным документом.
                            </div>
                            <div className="com_p">
                                7.	После получения жалобы ОС подтверждает, относится ли жалоба к его деятельности по сертификации, и только в этом случае принимает к рассмотрению. Необоснованные жалобы отклоняются.
                            </div>
                            <div className="com_p">
                                8.	ОС подтверждает получение официальной жалобы путём её регистрации в «Журнале регистрации жалоб и предложений»
                            </div>
                            <div className="com_p">
                                9.	Рассмотрение жалобы производится в течении срока не более одного месяца. При обсуждении каждая из заинтересованных сторон имеет право полностью изложить точку зрения и защитить свои интересы.
                            </div>
                            <div className="com_p">
                                10.	Руководитель ОС обязан принять меры по жалобе заказчика и устранению несоответствия. Он назначает сотрудника, ответственного за разрешение жалобы и (или) устранения несоответствия. Сотрудник анализирует и оценивает характер несоответствия, планирует и осуществляет меры по его устранению, заполняет раздел «корректирующие действия» в журнале регистрации жалоб заказчиков.
                            </div>
                            <div className="com_p">
                                11.	Решение по рассмотрению жалобы должно быть объективным и беспристрастным. Решения по удовлетворению жалобы принимаются или рассматриваться и утверждаться лицом или лицами, не принимавшим участия в деятельности по сертификации связанной с жалобой.
                            </div>
                            <div className="com_p">
                                12.	При установлении несоответствий принимается решение о проведении корректирующих действий, проводится проверка их выполнения «Корректирующие действия». При этом заявитель должен быть удовлетворен принятыми действиями.
                            </div>
                            <div className="com_p">
                                13.	В необходимых случаях предусматривается проведение предупреждающих действий
                            </div>
                            <div className="com_p">
                                14.	Решение по жалобе подготавливается ответственным сотрудником ОС после информирования заявителя и его согласия по разрешению жалобы, и подписывается всеми участвующими сторонами.
                            </div>
                            <div className="com_p">
                                15.	При не достижении согласия, жалоба передаётся на рассмотрение Технического Совета ОС. Решение Технического Совета по жалобе является окончательным.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Complaints