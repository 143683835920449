import React from 'react'

const SertifNum = () => {
    return (
        <>
            <div className="SertifNum">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="num_h">
                                Наименование
                                объекта сертификации
                                (вид продукции)
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="num_p">
                                1.  -Трансформаторы силовые 
                            </div>
                            <div className="num_p">
                                2.  -Трансформаторы напряжения
                            </div>
                            <div className="num_p">
                                3.  -Выключатели переменного тока на напряжение свыше 1000 V (Выключатели автоматические на напряжение менее 72,5 kV)
                            </div>
                            <div className="num_p">
                                4.  -Выключатели переменного тока на напряжение свыше 72,5 kV (Выключатели автоматические на напряжение более 72,5 kV)
                            </div>
                            <div className="num_p">
                                5.  -Разъединители и заземлители свыше 1000 V (Разъединители и прерыватели на напряжение свыше 1000V)
                            </div>
                            <div className="num_p">
                                6.  -Ограничители перенапряжений нелинейные для электроустановок переменного тока напряжением от 3 до 750 кВ
                            </div>
                            <div className="num_p">
                                7.  -Разрядники вентильные переменного тока на номинальные напряжения от 3,8 до 600 кВ
                            </div>
                            <div className="num_p">
                                8.  -Выключатели (Автоматические выключатели на напряжение до 1000 V)
                            </div>
                            <div className="num_p">
                                9.  -Устройства комплектные низковольтные распределения и управления до 1000V (Пульты, панели, консоли, столы, распределительные щиты и основания для электрической аппаратуры)
                            </div>
                            <div className="num_p">
                                10.  -Устройства комплектные распределительные свыше 1000V
                            </div>
                            <div className="num_p">
                                11.  -Подстанции трансформаторные комплектные
                            </div>
                            <div className="num_p">
                                12.  -Изоляторы электрические стеклянные
                            </div>
                            <div className="num_p">
                                13.  -Изоляторы электрические керамические
                            </div>
                            <div className="num_p">
                                14.  -Изоляторы электрические из пластмассы и других материалов 
                            </div>
                            <div className="num_p">
                                15.  -Вводы изолированные на номинальные напряжения свыше 1000 V переменного тока
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SertifNum