import React from 'react'
import Footer from './Footer'
import SertificatUse from './SertificatUse'

const Sertif2 = () => {
  return (
    <>
      <div className="Sertif2">

      </div>
      <SertificatUse />
      <Footer />
    </>
  )
}

export default Sertif2